/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);
	
		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);
		
		/*
		Catch and active URL
		*/
		var _url_active_main = './';
		
		$('.js-nav-item').removeClass('active');

		if(url[1] !== '') { 
			_url_active_main = url[1];
		}
		
		if(_url_active_main !== '') {
			$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
		}

		/*
		Slideshow: Clients ******************************************|
		Slideshow: Clients ******************************************|
		Slideshow: Clients ******************************************|
		Slideshow: Clients ******************************************|
		*/
		var swiperClients = new Swiper('.js-slideshow-clients', {
		  slidesPerView: 3,
		  spaceBetween: 0,
		  speed: 4000,
		  autoplay: {
			delay: 1,
		  },
		  loop: true,
		  allowTouchMove: false,
		  disableOnInteraction: true
		});
		
		/*
		Colorbox
		*/
		$(".colorbox").colorbox({
			iframe:true, 
			width:"80%", 
			height:"80%"
		});

		/*
		FAQ
		*/
		$(document).on('click','.js-faq-item',function() {

			$('.js-faq-item').find('.answer').stop().slideUp();

			$('.js-faq-item').find('.plus').hide();
			$('.js-faq-item').find('.minus').show();

			$(this).find('.answer').stop().slideDown();

			$(this).find('.minus').hide();
			$(this).find('.plus').show();

		});

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 
